import React, { useState } from 'react'
import { Link } from 'gatsby'

import Logo from '../images/logo.svg'

function Header(siteTitle) {
  const [isToggle, setToggle] = useState(true)

  return (
    <header>
      <div className="inner-header">
        <div
          className={isToggle ? 'menu' : 'menu open'}
          onClick={() => setToggle(!isToggle)}
        >
          <span class="menu-circle"></span>
          <a href="#" class="menu-link">
            <span class="menu-icon">
              <span class="menu-line menu-line-1"></span>
              <span class="menu-line menu-line-2"></span>
              <span class="menu-line menu-line-3"></span>
            </span>
          </a>
        </div>

        <div className={isToggle ? 'menu-overlay' : 'menu-overlay open'}>
          <ul className="menu-text">
            <li>
              <Link to="/" className="menu-text-child">
                anasayfa
              </Link>
              <Link to="/about/" className="menu-text-child">
                hakkımızda
              </Link>
              <Link to="/services/" className="menu-text-child">
                hizmetler
              </Link>
              <Link to="/works/" className="menu-text-child">
                işlerimiz
              </Link>
              <Link to="/career/" className="menu-text-child">
                kariyer
              </Link>
              <Link to="/contact/" className="menu-text-child">
                iletişim
              </Link>
            </li>
          </ul>

          <img src={Logo} alt="BM Creative Works Logo" className="menu-logo" />
        </div>
      </div>
    </header>
  )
}

export default Header
